import { ref, watch } from 'vue'
import _debounce from 'lodash.debounce'

const settings = JSON.parse(localStorage?.getItem('backpack.app.settings' || '{}'))?.muse || {}
const saveSettings = () => {
  const localSettings = JSON.parse(localStorage?.getItem('backpack.app.settings') || '{}')
  if (localSettings) {
    localSettings.muse = settings
    localStorage.setItem('backpack.app.settings', JSON.stringify(localSettings))
  }
}
const setSetting = _debounce((field, value) => {
  settings[field] = value
  saveSettings()
}, 200)

const museModal = ref(settings.modal || false)
const museOpen = ref(false)
const panelWidth = ref(settings.width || 25)
const modalPosition = ref({ top: settings.position?.top || 5, left: settings.position?.left || 70 })
const history = ref([])
const dataCollectors = ref([])
const promptLoading = ref(false)

const setHistory = (value) => {
  history.value = value
}

const addDataCollector = (callback) => {
  dataCollectors.value.push(callback)
}

const removeDataCollector = (callback) => {
  const i = dataCollectors.value.indexOf(callback)
  if (i >= 0) {
    dataCollectors.value.splice(i, 1)
  }
}

watch(
  () => panelWidth.value,
  (value) => {
    setSetting('width', value)
  }
)
watch(
  () => museModal.value,
  (value) => {
    setSetting('modal', value)
  }
)
watch(
  () => modalPosition.value,
  (value) => {
    setSetting('position', value)
  }
)
export const useMusePanel = () => {
  return { museOpen, panelWidth, museModal, modalPosition, history, setHistory, addDataCollector, removeDataCollector, dataCollectors, promptLoading }
}
